<template>
  <section>
    <div class="uk-flex-top loader-modal loader-modal" uk-modal esc-close="false" bg-close="false">
      <div class="uk-modal-dialog uk-margin uk-padding uk-text-center uk-width-1-3">
        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
        <h5 class="uk-margin-remove">Uploading...</h5>
      </div>
  </div>
  </section>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            required: true,
        },
    },
    watch: {
        "$props.value"() {
            if (this.$props.value) {
                window.UIkit.modal('.loader-modal').show();
            } else {
                window.UIkit.modal('.loader-modal').hide();
            }
        }
    }
};
</script>

<style scoped>
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #0275D8;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #0275D8 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
