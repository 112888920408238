<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
      <div class="uk-flex uk-flex-between uk-margin">
          <div class="uk-flex uk-flex-left">
              <h3>Limit Mitra</h3>
          </div>
          <div class="uk-flex uk-flex-right">
              <button
                  class="uk-close-large"
                  style="color: black;"
                  type="button"
                  uk-close
                  @click="$router.push({ name: 'MyWarung', query: { keywords: $route.query.keywords_warung } })"
              ></button>
          </div>
      </div>
      <div class="uk-flex uk-margin">
          <div v-if="consist(user_cred.roles, ['admin-community', 'qwe123'])" class="uk-flex uk-flex-right">
            <button 
              type="button"
              class="uk-button uk-button-primary uk-border-rounded uk-margin-right"
              @click="$refs.upload.click()"
            >
              <img :src="`${images}/outline-upload.svg`" alt="Upload icon" width="25">
              Upload Template
              <input
                ref="upload"
                style="display: none"
                type="file"
                accept=".xls,.xlsx"
                @change="uploadTemplate"
              />
            </button>
            <button
                class="uk-button uk-button-default uk-border-rounded uk-text-primary"
                style="border-color: #0f7ae5;"
                @click="downloadExcelLimitTemplate()"
                :disabled="isLoadingButton"
                :uk-spinner="isLoadingButton"
            >
              <img :src="`${images}/download-outline-blue.svg`" alt="" width="25">
              Download Template
            </button>
          </div>
      </div>
      <div class="uk-card uk-card-default">
          <div class="uk-overflow-auto">
              <table class="uk-table uk-table-medium uk-table-striped">
                  <thead>
                      <tr class="uk-text-left">
                          <th>Mitra Name</th>
                          <th>Company</th>
                          <th>Office</th>
                          <th>Role</th>
                          <th>Assigned to Warung</th>
                          <th>Spending Limit</th>
                          <th>Total Spending</th>
                          <th>Balance</th>
                          <th
                            v-if="consist(user_cred.roles, ['admin-community', 'qwe123'])"
                            class="uk-width-auto uk-text-center"
                          >
                            Action
                          </th>
                      </tr>
                  </thead>
                  <loading-table v-if="isLoading" :colspan="9"/>
                  <tbody v-else-if="page_data.docs.length>0">
                    <tr v-for="(data, i) in page_data.docs" :key="i" class="uk-text-left">
                      <td><div class="one-line-text">{{ dataUser.result.find(d => d._id === data.user_id).fullname }}</div></td>
                      <td><div class="one-line-text">{{ getPartner('company_id', data.user_id) }}</div></td>
                      <td><div class="one-line-text">{{ getPartner('company_office_id', data.user_id) }}</div></td>
                      <td><div class="one-line-text">{{ getPartner('role_id', data.user_id) }}</div></td>
                      <td><div class="two-line-cell">{{ data.warungs.map(d => d.name).join(", ") }}</div></td>
                      <td><div class="one-line-text" :class="[data.balance < 0 && 'uk-text-danger']">Rp{{ formatter.thousandSeparator(data.limit) }}</div></td>
                      <td><div class="one-line-text" :class="[data.balance < 0 && 'uk-text-danger']">Rp{{ formatter.thousandSeparator(data.spending) }}</div></td>
                      <td><div class="one-line-text" :class="[data.balance < 0 && 'uk-text-danger']">Rp{{ formatter.thousandSeparator(data.balance) }}</div></td>
                      <td v-if="consist(user_cred.roles, ['admin-community', 'qwe123'])" class="uk-text-center">
                        <button class="uk-button uk-button-small uk-button-default" type="button" >Actions</button>
                        <div uk-dropdown="mode: click">
                          <ul class="uk-nav uk-dropdown-nav uk-text-left">
                            <li><a @click="showModal(data)">Edit Spending Limit</a></li>
                            <li class="uk-nav-divider" />
                            <li>
                              <span v-if="!data.limit" style="color: #cac8c8">Reset Balance</span>
                              <a v-else class="uk-padding-remove-bottom" @click="showModalDelete(data)">Reset Balance</a>
                              <div style="font-size: 10px; color: #cac8c8">Reset Count History: {{ data.reset_count }}</div>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <empty-table v-else :colspan="9"/>
              </table>
          </div>
          <pagination
              :total-data="page_data.totalDocs"
              :change-limit="changeLimit"
              :change-page="changePage"
              :limit="meta.limit"
          />
      </div>
      <Loader v-model="isUploadTemplate" />
      <EditLimitModal v-if="showCreateModal" :data="form" @hideModal="hideModal" />
      <ConfirmResetModal v-if="deleteData.isShow" :data="deleteData" @hideModal="hideModal" @confirmDelete="confirmReset" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import LoadingTable from '@/components/globals/tables/Loading';
import Pagination from '@/components/globals/Pagination';
import { getUserLogin } from '@/utils/auth';
import formatter from "@/utils/formatter";
import { PREFIX_IMAGE } from '@/utils/constant';
import Loader from '@/components/globals/Loader';
import EditLimitModal from '@/components/modals/community/mywarung/edit_limit';
import ConfirmResetModal from '@/components/globals/modals/confirm_delete';
import {
    notificationSuccess,
    notificationDanger,
    notificationDangerCustom
} from '@/utils/notification';
import { excelDownloader } from '@/utils/helper';

export default {
    data(){
        return {
            isUploadTemplate: false,
            isLoadingButton: false,
            images: PREFIX_IMAGE,
            user_cred: getUserLogin(),
            dataUser: { result: [] },
            dataPartnership: { result: [] },
            meta: {
                limit: 10,
                page: 1,
                name: this.$route.query.keywords ?? null
            },
            form: {
                user_id: null,
                limit: "0",
            },
            keywords: this.$route.query.keywords ?? null,
            page_data: { docs: [], totalDocs: 0 },
            isLoading: true,
            showCreateModal: false,
            formatter,
            deleteData: {
                isShow: false,
                title: 'Are you sure to reset the balance of this Mitra?',
                confirmText: 'Reset',
                _id: null,
                body: {
                    "Full Name": null,
                    "Office": null,
                    "Balance Left": null,
                }
            }
        };
    },
    components: {
        EmptyTable,
        Pagination,
        LoadingTable,
        Loader,
        EditLimitModal,
        ConfirmResetModal,
    },
    watch: {
        meta() {
            this.getInitPageData();
        },
    },
    async mounted() {
        await this.getInitPageData();
    },
    methods: {
        ...mapActions({
            getWarungLImit: 'warung/getWarungLimit',
            resetWarungLImit: 'warung/resetWarungLImit',
            getUserFullname: 'internal_api/getUserFullname',
            getUserPartnership: 'internal_api/getUserPartnership',
            uploadWarungLImit: 'warung/uploadWarungLImit',
        }),
        
        async uploadTemplate(e) {
            this.isUploadTemplate = true;

            try {
                const filepath = e.target.files[0];
                let formData = new FormData();
                formData.append('file', filepath);

                const response = await this.uploadWarungLImit(formData);

                this.$refs.upload.value = null;
                
                if(response && response.status === 'OK') {
                    notificationSuccess('Upload Succesful!');
                    this.getInitPageData();
                } else {
                    notificationDanger('Upload failed!');
                }

                this.isUploadTemplate = false;
            } catch (error) {

                this.isUploadTemplate = false;
                notificationDanger(error);
            }
        },

        getPartner(data, user_id) {
            const partner = this.dataPartnership.result.find(d => d.user_id === user_id);

            if (partner) {
                return partner[data].name;
            } else {
                return null;
            }
        },

        async getInitPageData(){
            this.isLoading = true;
            this.page_data = await this.getWarungLImit(this.meta);

            [this.dataUser, this.dataPartnership] = await Promise.all([
                this.getUserFullname({user_ids: this.page_data.docs.map(d => d.user_id)}),
                this.getUserPartnership({user_ids: this.page_data.docs.map(d => d.user_id)})
            ]);

            this.isLoading = false;
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        resetForm() {
            this.form = {
                user_id: null,
                limit: "0",
            };
        },
        showModal(data){
            this.form.user_id = data.user_id;
            this.form.limit = data.limit ?? "0";
            this.showCreateModal = true;
        },
        showModalDelete(data){
            this.deleteData._id = data.user_id;
            this.deleteData.body["Full Name"] = this.dataUser.result.find(d => d._id === data.user_id).fullname;
            this.deleteData.body["Office"] = this.getPartner('company_id', data.user_id);
            this.deleteData.body["Balance Left"] = `Rp${formatter.thousandSeparator(data.balance)}`;
          
            this.deleteData.isShow = true;
        },
        hideModal(isRefresh){
            if (isRefresh) this.getInitPageData();
          
            this.showCreateModal = false;
            this.deleteData.isShow = false;
            this.resetForm();
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
        async confirmReset(id) {
            this.isLoading = true;
            const response = await this.resetWarungLImit(id);
            if (response && response.status === 'OK') {
                notificationSuccess('reseted!');
                this.hideModal(true);
            } else {
                this.isLoading = false;
                notificationDanger('Failed to reset!');
            }
        },
        async downloadExcelLimitTemplate() {
            if (this.isLoadingButton) {
                return notificationDangerCustom('Another download is in progress, please wait.');
            }
            this.isLoadingButton = true;

            try {
                const excelMap = this.page_data.docs.map(data => {
                    const name = this.dataUser.result.find(d => d._id === data.user_id).fullname;
                    const office = this.getPartner('company_office_id', data.user_id);
                    const company = this.getPartner('company_id', data.user_id);
                    const role = this.getPartner('role_id', data.user_id);
                    const warungList = data.warungs.map(d => d.name).join(", ");
                    return {
                        "Mitra Name": name,
                        "User ID": data.user_id,
                        "Company": company,
                        "Office": office,
                        "Role": role,
                        "Assigned To Warung": warungList,
                        "Spending Limit": data.limit || "",
                        "Total Spending": data.spending || 0,
                        "Balance": data.balance || "",
                    };
                });
                excelDownloader(excelMap, `Limit MyWarung.xlsx`);
            } catch (error) {
                notificationDanger(error);
            }

            this.isLoadingButton = false;
        },
    },
};
</script>

<style scoped>
.one-line-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 200px;
}
.two-line-cell {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 200px;
}
</style>
