<template>
  <section>
    <div id="mainModal" uk-modal esc-close="false" bg-close="false">
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" type="button" uk-close @click="hideModal(false)"></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title">Edit Form</h2>
        </div>
        <div class="uk-modal-body">
          <div class="uk-margin">
            <label class="uk-form-label" style="display: block">Edit Spending Limit <b class="uk-text-danger">*</b></label>
            <div class="uk-inline uk-width-1-1">
              <span class="uk-form-icon" style="background-color: #E5E5E5; color: #343434;">Rp</span>
              <input
                style="padding-left: 50px !important"
                class="uk-input"
                :class="{'uk-form-danger': errors.has('limit')}"
                name="limit"
                type="text"
                v-model="form.limit"
                v-validate="'required'"
                @keyup="formatText"
              />
            </div>
            <span class="uk-text-small uk-text-danger" v-show="errors.has('limit')">{{ errors.first('limit') }}</span>
          </div>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <section v-if="isLoading">
            <button class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
          </section>
          <section v-else>
            <button
              class="uk-button uk-button-default uk-border-rounded uk-margin-right uk-text-primary"
              type="button"
              style="border-color: #0f7ae5;"
              @click="hideModal(false)"
            >
              Cancel
            </button>
            <button class="uk-button uk-button-primary uk-border-rounded" type="button" @click="sendForm">Save</button>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import formatter from "@/utils/formatter";
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';
export default {
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    data(){
        return {
            form: {
                user_id: null,
                limit: null,
            },
            isLoading: false,
        };
    },
    mounted() {
        window.UIkit.modal('#mainModal').show();
        this.form = this.$props.data;
    },
    watch: {
        "form.limit"() {
            this.formatText();
        }
    },
    methods: {
        ...mapActions({
            SetLimitWarung: 'warung/SetLimitWarung',
        }),
        hideModal(isRefresh) {
            window.UIkit.modal('#mainModal').$destroy(true);
            this.$emit("hideModal", isRefresh);
        },
        formatText() {
            const balance = this.form.limit.toString().replace(/[^0-9]/g, '');
            this.form.limit = formatter.thousandSeparator(balance);
        },
        async sendForm() {
            try {
                const validate = await this.$validator.validateAll();
                if (!validate || this.$validator.errors.any()) return;

                this.isLoading = true;
                if (this.form.limit !== undefined) {
                    this.form.limit = this.form.limit.toString().replace(/[^0-9]/g, '');
                }

                const response = await this.SetLimitWarung({ user_id: this.form.user_id, formData: { limit: parseInt(this.form.limit) } });

                this.isLoading = false;
                if (response && response.status === 'OK') {
                    notificationSuccess('Saved!');
                    this.hideModal(true);
                } else {
                    notificationDanger('Failed to save!');
                }
            } catch (err) {
                this.isLoading = false;
                notificationDanger(err);
            }
        },
    }
};
</script>
